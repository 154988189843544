<template>
  <div class="mypage_curation_send_wrap">
    <!--        내 응모 아이템 리스트 레이아웃-->
    <table class="my_history_table" width="100%"  border="0" cellspacing="0" cellpadding="0"
           style = "table-layout: auto; width: 1200px; table-layout: fixed;">
      <colgroup>
        <col width="95px" />
        <col width="130px" />
        <col width="220px" />
        <col width="130px" />
        <col width="220px" />
        <col width="140px" />
        <col width="140px" />
        <col width="120px" />
      </colgroup>
      <tr class="title_row">
        <th colspan="1">No</th>
        <th colspan="1">{{$t('image')}}</th>
        <th colspan="1">{{$t('item')}} {{$t('subject')}}</th>
        <th colspan="1">{{$t('get_vote')}}</th>
        <th colspan="1">{{$t('competition')}} {{$t('subject')}}</th>
        <th colspan="1">{{$t('status')}}</th>
        <th colspan="1">{{$t('entry')}}{{$t('dateTime')}}</th>
        <th colspan="1">{{$t('entry')}}{{$t('cancel')}}</th>
      </tr>
      <tr v-for="competitionLayout in reverseKeys(competitionLayouts)" :key="`competition${competitionLayouts}`">
        <td>{{ competitionLayout + 1 }}</td>  <!-- No -->
        <td><img src="@/assets/image/main/award_temp_img2.jpg" width="78" height="49"/></td>
        <td class="t_subject">아이템명</td>  <!-- 아이템 제목 -->

        <td>123,102(32%)</td> <!-- 득표 -->

        <td class="t_subject">공모전명</td>  <!-- 공모전제목 -->


        <td v-if="competitionLayout <= 4">
          {{$t('in_process2')}}
        </td> <!-- 상태 -->
        <td v-else-if="competitionLayout === 5">
          {{$t('scheduled_to_proceed')}}
        </td>  <!-- 상태 -->
        <td v-else-if="competitionLayout === 6">
          {{$t('under_review')}}
        </td>
        <td v-else>
          {{$t('p_completed')}}
        </td>

        <td>2021-01-23<br/>16:57</td> <!-- 응모일시 -->
        <td v-if="competitionLayout % 3 === 0">
          <button @click="cancelRequest()" class="endDate">{{$t('entry')}}{{$t('cancel')}}</button>
        </td>  <!-- 응모취소 -->
        <td v-else>-</td>
      </tr>
    </table>
  </div>
</template>

<script>
import alertMixins from "@/mixins/alertMixins";
export default {
  name: "ItemListMyEntryLayout",
  mixins: [alertMixins],
  components: {},
  inject: [],
  provide() {
    return {}
  },
  props: {},
  data() {
    return {
      competitionLayouts: 10,
      tempSubject: this.$t('temp_info')
    }
  },
  beforeRouterEnter() {
  },
  created() {
  },
  mounted() {

  },
  beforeDestroy() {
  },
  destroyed() {
  },
  computed: {},
  methods: {
    movePage(url) {
      this.$router.push(`${this.$route.path}/${url}`)
    },
    reverseKeys(n) {
      return [...Array(n).keys()].slice().reverse()
    },
    cancelRequest() {
      this.createConfirm({
        title: '',
        content: " '<span class='subject'>"+ this.tempSubject + "</span>'<br>" + this.$t('my_cancel_confirm2') +
            "<br>" + this.$t('my_cancel_confirm3') + "",
        confirmText : this.$t('yes'),
        cancelText : this.$t('cancel'),
        confirm: () => {

        },
        cancel: () => {

        }
      })
    },
  },
  watch: {},
}
</script>

<style scoped>

</style>